import React from 'react';
import './index.css';
import { WorkerCard } from '../../pages/Workers';
import { ContentContext } from '../../contexts/ContentContext';
import Profile from '../../pages/Profile';

type props = {
  card: WorkerCard
}

const shortenDescription = (str: string) => {
  const cutTo = 100;
  if (str.length < cutTo) {
    return str;
  }
  return str.slice(0, cutTo) + '...';
}

const Card: React.FC<props> = ({ card }) => {
  const content = React.useContext(ContentContext);
  return (
    <div className='Card' onClick={() => {content.setContent(<Profile card={card} />)}}>
      <div className='Card-Photo'>
        <div className='Image'>
          { card.image }
        </div>
      </div>
      <div className='Card-Blurb'>
        <span style={{fontSize: 'larger', 'marginBottom': '0'}}> { card.name }</span>
        <div className='Title'>
          <span style={{color: '#7a7a7a', fontSize: 'smaller'}}>{ card.title }</span>
          <div className='Title-Underline'/>
        </div>
        <div className='Blurb-Content'>
          { shortenDescription(card.desc_text) } <span className='Read-More'>Read More</span>
        </div>
      </div>
    </div>
  );
}
export default Card;
