import React from 'react';
import './index.css';
import michele from './michele.jpg';
import liz from './liz.jpg';
import sarah from './sarah.jpg';
import jen from './jen.jpg';
import ben from './ben.jpg';
import jen_l from './jen_l.jpg';
import polina from './polina.png';
import Carousel from 'react-elastic-carousel';

import Card from '../../components/Card';

export type WorkerCard = {
  name: string
  title?: string
  image?: JSX.Element
  description: JSX.Element
  desc_text: string
}

const Jen: WorkerCard = {
  name: 'Jennifer Mckerral',
  title: 'Psychologist',
  image: <img src={jen} alt="Jennifer"/>,
  description: <>
    Jennifer studied at Sydney University majoring in psychology and history. She is a psychologist with over 25 years experience, having worked in public health, private hospitals, charities, NGOs and in custodial settings.<br/><br/>
    Jennifer has a strong focus on trauma informed care, and has specialized on delivering services to victims of crime and first responders.<br/><br/>
    She has consulted to Victims Services and Juvenile Justice, delivering programs for young people in custody, both in individual and group settings. She is exceptionally proud of the work she and her colleagues did with young women in detention, assisting them to run their own art exhibition of work representing their own journey’s through childhood trauma.<br/><br/>
    Jennifer has worked as a clinician, a manager, a service director and clinical advisor. She has sat on advisory committees for both NGOs and as psychologist advisor to the Commissioner of Victims Services.<br/><br/>
    Jennifer has delivered numerous training packages to other clinicians who work with trauma survivors. This has included train the trainer, and working with survivors of trauma in institutional settings.<br/><br/>
For the last 21 years she has run a successful private practice.
Outside of work, Jennifer is passionate about her family, her friends, and her dogs.
  </>,
  desc_text: 'Jennifer studied at Sydney University majoring in psychology and history. She is a psychologist with over 25 years experience, having worked in public health, private hospitals, charities, NGOs and in custodial settings.'
}

const Michele: WorkerCard = {
  name: 'Michele Khan',
  title: 'Practice Manager',
  image: <img src={michele} alt="Michele"/>,
  description: <>
    Michele Khan is our wonderful practice manager. She handles all aspects of booking appointments, managing client issues, liaising with Medicare, insurers, government services and funding bodies.<br/>
Michele grew up in Ireland where she studied Business while developing her fabulous accent.
  <br/><br/>
She has worked in Customer Service and Recruitment for most of her career, living and working in a number of different countries. She brings with her a wealth of admin management experience and enjoys being the person behind the scenes supporting her colleagues.
  <br/><br/>
However client interaction has always been Michele’s favourite part of the job. She has a calming and kind nature, making her perfect for the position of practice manager in a counselling setting. Our feedbacks from our clients about their interactions with Michele is always positive.
  <br/><br/>
Michele loves her little family and is a keen soccer player (being Irish helps her in this regard). She is a committee member for her local soccer club. She is also our resident comedian bringing lots of fun and laughter to a sometimes difficult job.
  </>,
  desc_text: 'Michele Khan is our wonderful practice manager. She handles all aspects of booking appointments, managing client issues, liaising with Medicare, insurers, government services and funding bodies.'
}

const Liz: WorkerCard = {
  name: 'Dr. Elizabeth Davies',
  title: 'Mental Health Social Worker',
  image: <img src={liz} alt="Elizabeth"/>,
  description: <>
Elizabeth (or Liz) Davies is an Accredited Mental Health Social Worker who has worked
extensively in the public sectors of Health, and Justice, as a clinician, a counsellor and a
manager, working with people of all ages who have experienced trauma and loss.<br/><br/>
Liz has provided training to a range of mental health professionals, and staff working in corrections
and policing from frontline to senior executives on a range of topics, which include trauma-
informed practice, vicarious trauma, accidental counselling, grief and loss.<br/><br/>Liz now works in
private practice delivering Telehealth and online counselling to clients experiencing a range
of relationship and mental health challenges and difficulties. She is committed to providing
clinical and workplace supervision to mental health professionals ensuring that staff are
equipped to meet the professional challenges of the work they do while caring for
themselves thus ensuring they are able to continue to deliver best practice.<br/><br/>
In 2020, Liz completed a PhD in the area of ambiguous loss and trauma – specifically the
experience of young people when a loved one is a long-term missing person. She has a
particular interest in working with people where their experience of trauma and loss may be
disenfranchised, and their support needs not readily recognised.<br/><br/>
Outside of work, Liz is busy, active, and focused on maintaining a healthy lifestyle – walking
every day, skiing on fine days, breathing fresh air, and looking at the stars. She also works
hard on making time to stop and smell the roses and spending time with her family.
  </>,
  desc_text: 'Elizabeth (or Liz) Davies is an Accredited Mental Health Social Worker who has worked extensively in the public sectors of Health, and Justice, as a clinician, a counsellor and'
}

const Sarah: WorkerCard = {
  name: 'Dr. Sarah Barrett Jones',
  title: 'Psychologist',
  image: <img src={sarah} alt="Sarah"/>,
  description: <>
  Sarah is a clinical psychologist who works in private practice on a Saturday. She has a particular
interest in anxiety and depression in both adolescents and adults. Prior to becoming a psychologist, Sarah was a university academic. She holds a PhD in Linguistics from the University of Cambridge. Sarah has experience working in
private psychiatric hospitals with adults who have complex mental health needs as well as working
    in clinics with children/adolescents with behavioural challenges.<br/><br/>When Sarah is not in private
practice, she works with young offenders in a custodial setting. Sarah offers bulk billing to clients
experiencing financial hardship.
  </>,
  desc_text: 'Sarah is a clinical psychologist who works in private practice on a Saturday. She has a particular interest in anxiety and depression in both adolescents and adults. Prior to becoming a psychologist, Sarah was a university academic. She holds a PhD in Linguistics from the University of Cambridge. Sarah has experience working in'
}

const Jen_L: WorkerCard = {
  name: 'Jennifer Lalic',
  title: 'Training Consultant',
  image: <img src={jen_l} alt="Jennifer Lalic"/>,
  description: <>
Jennifer L (yes , there are two Jen's and Jenny is NOT an option apparently) has spent her career in the Youth Justice space, working in custodial and community environments for over 10 years with at risk youth and enhancing practitioner knowledge and skills. 
  <br/>
  <br/>
Jen likes to dip her fingers in many pies, and has also worked in project roles with a strong training focus, for various teams and agencies,  overseeing key practice projects and system enhancements. Jen is a natural when it comes to training, coaching and mentoring frontline staff in upholding trauma informed practice. She likes to tell anyone who will listen that she knew she was made for the spotlight (aka training and coaching roles) ,  having won so many public speaking and debating titles in her schooling years. She's animated, personable and always switched on ! 
  <br/>
  <br/>
In a previous role, she worked in a custodial unit with a multidisciplinary team,  helping the state's highest risk young men diagnosed with developmental trauma to heal from their trauma. Although Jen thought her driving force was her obsession for getting to know the criminal mind for most of her career, the frontline experience with young people who have complex trauma showed her that her true passion lies in trauma related work.  
  <br/>
  <br/>
Jen has qualifications in the following areas :
  <ul>
    <li>Bachelor of social science and policy (Major in Criminology)</li>
    <li>Masters In International Relations </li>
    <li>Diploma of Leadership and Management </li>
  </ul>

Jen's also a qualified dance teacher (she thought she would throw that one in.)
  <br/><br/>
  <i>Jen's a true Gemini with a gypsy soul that yearns to travel around but that that's grounded to NSW because of her work and the beautiful clients she gets to know</i>
  </>,
  desc_text: 'Jennifer L (yes , there are two Jen\'s and Jenny is NOT an option apparently) has spent her career in the Youth Justice space, working in custodial and community environments for over 10 years with at risk youth and enhancing practitioner knowledge and skills. '
}

const Ben: WorkerCard = {
  name: 'Ben Braham',
  title: 'Chief Technology Officer',
  image: <img src={ben} alt="Ben"/>,
  description: <>
  Ben is a theoretical physicist, computer scientist, and software developer with experience in both industry and research.<br/><br/>
  Ben manages the IT requirements and system administration of the Raven Initiative. Additionally, he currently works in a research assistant role, studying quantum computation. He is also studying full time at the University of Sydney as an undergraduate student. 
  <br/><br/>
  Outside of work, Ben is passionate about music. After training as a classical pianist for most of his life, he is now exploring the world of musical composition. For more info and contact, check out <a href='https://benbraham.com'>benbraham.com</a>.
  </>,
  desc_text: 'Ben is a theoretical physicist, computer scientist, and software developer with experience in both industry and research. Ben manages the IT requirements and system administration of the Raven Initiative. Additionally, he works in a research assistant role, studying quantum computation. He is also studying at the University of Sydney.'
}

const Polina: WorkerCard = {
  name: 'Polina Udachina',
  title: 'Business Associate / Psychologist',
  image: <img src={polina} alt="Polina"/>,
  description: <>
  <h2>About</h2>
  <p>I am a Registered Psychologist with a broad range of experience working with adolescents and adults with complex mental health needs. I completed my studies at Sydney University.  I have worked in the industry since 2008  and in Private Practice since 2014. I have worked across a range of government, non-government and private settings, in multidisciplinary and transdisciplinary teams. My background is in working with marginalised communities, I am experienced in working with involuntary clients and young offenders in community and custodial settings, and in the education and development sector in Primary and High Schools, as well as my Private Practice. I specialise in provision of client led, trauma informed, evidence based therapy. My goal is to empower my clients, and for them to have the best possible relationship with themselves and others in their life, and for them to engage meaningfully in the world around them.</p>
  <p>Outside of work, I am a parent in a blended family, I love to travel and be in nature and spend time with the people I care about.</p>
  <h2>Services</h2>
  <p>I am currently operating online doing telehealth counselling sessions with local and international clients. I am registered as a counsellor with Victims Services and also support clients with Work and Development Orders. I enjoy working with individuals, their family members, partners and significant others.</p>

  <p>I am confident in supporting individuals in addressing issues around mental health issues such as Depression, Anxiety, Panic Disorder, and Post Traumatic Stress Disorder, as well as other issues including eating disorders, drug and alcohol addiction, gambling, grief and loss, role transitions, performance issues, illness, and chronic pain, with neurotypical and neurodiverse individuals.</p>

  <p>I operate using an eclectic approach, tailored to cater to each individual’s needs. I mainly utilise Cognitive Behavioural Therapy, Inter-Personal Therapy, Acceptance and Commitment Therapy, Solution-Focused Brief Therapy, Rational Emotive Behaviour Therapy, Schema Therapy, Positive Psychology and Motivational Interviewing techniques.</p>

  <h2>Fees</h2>
  <p>My fees are $160 per session.</p>
  <h2>Bookings</h2>
  <p>To book appointments, please <a href='https://my.powerdiary.com/clientportal/fp5fe'>click here.</a></p>
  </>,
  desc_text: 'Polina is a Registered Psychologist with a broad range of experience working with adolescents and adults with complex mental health needs. She completed her studies at Sydney University. She has worked in the industry since 2008 and in Private Practice since 2014. She has worked across a range of government, non-government and private settings, in multidisciplinary and transdisciplinary teams.'
}


const workers = [
  Jen, Liz, Sarah, Michele, Polina, Ben
    ]

const Workers: React.FC = () => {
  return (
    <div className='Workers'>
      <div className='Cards'>
        <Carousel className='noselect' isRTL={false} breakPoints={[
          {width: 1, itemsToShow: 1},
          {width: 550, itemsToShow: 2},
          {width: 850, itemsToShow: 3},
          {width: 1150, itemsToShow: 4, itemsToScroll: 2},
          {width: 1450, itemsToShow: 5}
        ]}
        itemPadding={[10]}
        >
          {workers.map(worker => <Card key={worker.name} card={worker}/>)}
        </Carousel>
      </div>
    </div>
  )
}
/*
        <Card name={Example.name} title={Example.title} image={Example.image} description={Example.description} />
        <Card name={Example.name} title={Example.title} image={Example.image} description={Example.description} />
        <Card name={Example.name} title={Example.title} image={Example.image} description={Example.description} />
        <Card name={Example.name} title={Example.title} image={Example.image} description={Example.description} />
 */

export default Workers;
