import React from 'react';
import './index.css';

const Contact: React.FC = () => {
  return (
    <div className='Contact'>
      <br/>
      <b>Please note:</b> our mail server is currently under maintenance. Please reach out via mobile only for now.
      <br/>
      <div className='Cards'>
        <div className='Contact-card'>Jennifer McKerral <br/> Mobile: 0413148499</div>
        <div className='Contact-card'>Elizabeth Davies <br/> Mobile: 0413521730</div>
        <div className='Contact-card'>Sarah Barrett Jones <br/> Mobile: 0405331124</div>
        <div className='Contact-card'>Michele Khan <br/> Mobile: 0466441498</div>
        <div className='Contact-card'>All written correspondence to: PO Box 115, Berowra Heights, NSW 2082</div>
      </div>
    </div>
  )
}

export default Contact;
