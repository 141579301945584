import React from 'react';
import './index.css';

type props = {
  text: string
  callback: () => void
  selected: boolean
}

const HeaderItem: React.FC<props> = ({ text, callback, selected }) => {
  return (
    <div className='HeaderItem' onClick={callback}>
      <div className='HeaderItemText' style={{color: selected ? '#5A22B4' : 'black'}}>
        { text }
      </div>
    </div>
  );
}

export default HeaderItem;
