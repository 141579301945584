import React from 'react';
import './App.css';

import Home from './pages/Home';
import Header from './components/Header';

import { ContentProvider, contentContextType } from './contexts/ContentContext';

const App: React.FC = () => {

  const [contentContext, setContentContext] = React.useState<contentContextType>({
    content: <Home />,
    setContent: (page: JSX.Element) => setContentContext({
      ...contentContext,
      content: page
    })
  })

  return (
    <div className='App'> 
      <ContentProvider value={contentContext}>
        <Header />
        <div className='Content'>
          { contentContext.content }
        </div>
      </ContentProvider>
    </div>
  );
}

export default App;
