import React from 'react'
import './index.css'

type props = {
  rotated: boolean
}

const Hamburger: React.FC<props> = ({ rotated }) => {
  return (
    <div className='Hamburger noselect' style={{'transform': rotated ? 'rotate(90deg)' : ''}}>
      <hr/>
      <hr/>
      <hr/>
    </div>
  )
}

export default Hamburger
