import React from 'react';

export type contentContextType = {
  content: JSX.Element
  setContent(page: JSX.Element): void
}

const defaultContent: contentContextType = {
  content: <div/>,
  setContent: (page: JSX.Element) => {}
}

export const ContentContext = React.createContext(defaultContent)
export const ContentProvider = ContentContext.Provider
