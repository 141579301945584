import React from 'react';
import './index.css';
import { ContentContext } from '../../contexts/ContentContext';
import raven from './raven.png';

import HeaderItem from '../HeaderItem';
import Hamburger from '../Hamburger';

import Home from '../../pages/Home';
import Contact from '../../pages/Contact';
import Workers from '../../pages/Workers';

const Header: React.FC = () => {
  const [width, setWidth] = React.useState(window.innerWidth);
  const [showDropDown, setShowDropDown] = React.useState(false);
  const [selected, setSelected] = React.useState([true, false, false])
  const content = React.useContext(ContentContext);

  React.useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className='Header noselect'>
      <div className='HeaderMain'>
        <div className='Logo'>
          <img alt="Raven logo" style={{cursor: 'pointer'}} onClick={() => {content.setContent(<Home />);setSelected([true, false, false])}} src={raven}/>
        </div>
        <div className='Links'>
          { (width > 600) && <>
            <HeaderItem text='Home'
            callback={() => {content.setContent(<Home />);setSelected([true, false, false])}}
            selected={selected[0]}/>
            <HeaderItem text='Our Team'
            callback={() => {content.setContent(<Workers />);setSelected([false, true, false])}}
            selected={selected[1]}/>
          <HeaderItem text='Contact'
          callback={() => {content.setContent(<Contact />);setShowDropDown(false);setSelected([false, false, true])}}
          selected={selected[2]}/>
          </>}
          { (width <= 600) && <div className='HamburgerStorage' onClick={() => setShowDropDown(!showDropDown)}>
            <Hamburger rotated={showDropDown} />
          </div> }
        </div>
      </div>
    <div style={{height: showDropDown ? '5em' : '0px'}} className='LinksOverflow'>
      <HeaderItem text='Home'
      callback={() => {content.setContent(<Home />);setShowDropDown(false);setSelected([true, false, false])}}
      selected={selected[0]}/>
      <HeaderItem text='Our Team'
      callback={() => {content.setContent(<Workers />);setShowDropDown(false);setSelected([false, true, false])}}
      selected={selected[1]}/>
      <HeaderItem text='Contact'
      callback={() => {content.setContent(<Contact />);setShowDropDown(false);setSelected([false, false, true])}}
      selected={selected[2]}/>
    </div>
    </div>
  );
}

export default Header;
