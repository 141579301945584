import React from 'react';
import './index.css';
import { WorkerCard } from '../../pages/Workers';
import { ContentContext } from '../../contexts/ContentContext';
import Workers from '../../pages/Workers';

type props = {
  card: WorkerCard
}


//<div className='Back' onClick={() => {content.setContent(<Workers/>)}}>
//BACK
//</div>

const Profile: React.FC<props> = ({ card }) => {
  const content = React.useContext(ContentContext);
  return (
    <div className='Profile'>
        <div className='Profile-Content'>
          <div className='Info'>
              <div className='Name'>
                  <h1>{ card.name }</h1>
              </div>
              <div className='Title'>
                  <h2>{card.title}</h2>
              </div>
              <div className='Profile-Image'>
                  { card.image }
              </div>
          </div>
          <div className='Description'>
            { card.description }
          <div className='padding'/>
        </div>
      </div>
    </div>
  )
}

export default Profile;
